.main-notification {
    padding: 30px 110px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    height: calc(100vh - 283px);
    overflow-y: auto;

    .noti-box {
        margin-bottom: 15px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #00000008;
        padding: 20px;

        .icon-para-main {
            display: flex;

            .icon {
                margin-right: 15px;

                img {
                    height: 36px;
                    width: 36px;
                }
            }

            .paragraph-noti {
                p {
                    letter-spacing: 0.48px;
                    color: #222222;
                    font-size: 16px;
                }

                .time-date {
                    margin-top: 5px;
                    label {
                        letter-spacing: 0.42px;
                        color: #777777;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.textset{
    text-align: center;
}
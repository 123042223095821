.main-profile {
    height: calc(100vh - 283px);
    background: #F7F7F7 0% 0% no-repeat padding-box;
    overflow-y: auto;
    padding-bottom: 30px;

    .banner {
        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
    }

    .outer {
        display: flex;
        justify-content: center;

        .main-profile-card {
            padding: 0 20px 20px 20px;
            margin-top: -80px;
            position: relative;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 16px #00000008;
            width: 1000px;

            .edit-btn {
                button {
                    border: none;
                    background-color: #198754;

                    img {
                        width: 14px;
                        height: 14px;
                    }
                }

                position: absolute;
                // right: 20px;
                // top: 20px;

            }

            .img {
                position: absolute;
                top: -60px;
                left: 50%;
                transform: translateX(-50%);

                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    width: 120px;
                    height: 120px;
                    background: #FDE7EC 0% 0% no-repeat padding-box;
                    box-shadow: 0px 4px 16px #00000033;


                    img {
                        border-radius: 50%;
                        width: 110px;
                        height: 110px;

                        box-shadow: 0px 4px 16px #00000033;
                        border: 3px solid #ffff;



                    }
                }
            }
        }
    }

    .image_round35{
        height: 200px;
        width: 200px;
        filter: drop-shadow(1px 5px 5px gray);
        padding-top: 15px;
        border-radius: 25px;
    }

    .image_round36{
        filter: drop-shadow(1px 5px 5px gray);
        padding-top: 7px;
        border-radius: 25px;
        width: 280px;
    }  


    .image_round37{
        padding-top: 50px;
    }    

    .form-profile {
        margin-top: 25px;

        .form-control {
            border: none;
            border-bottom: 1px solid #E7E7E7;
            font-size: 16px;
            letter-spacing: 0.38px;
            color: #222222;
            padding-left: unset;
        }

        .form-label {
            letter-spacing: 0.29px;
            color: #777777;
            font-size: 12px;
        }
    }
}
.main-my-orders {
    padding: 30px 110px;
    background-color: #F7F7F7;

    ::-webkit-scrollbar {
        display: none !important;
    }

    .tab-my-order {
        overflow-x: auto;
        .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
            background: #FF3E6C 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 16px #FF3E6C4F;
            letter-spacing: 0.34px;
            color: #FFFFFF;
        }
        .nav{
            margin-bottom: 30px ;
        }
        .nav-link {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 16px #0000000F;
            letter-spacing: 0.34px;
            color: #AAAAAA;
            font-size: 14px;
            border-radius: unset;
            margin-right: 15px;
            height: 38px;
        }
    }
    .tab-body {
        height: calc(100vh - 321px);
        overflow-y: auto;
    }

    .mayOrder-status-main {
        .order-box {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 16px #00000008;
            padding: 10px;
            margin-bottom: 30px;
            position: relative;
        }

        .inner-content {
            display: flex;
            justify-content: space-between;
        }

        .completed-inner {
            align-items: center;
        }

        .left-content {
            display: flex;
            align-items: center;

            .image {
                margin-right: 15px;

                img {
                    width: 120px;
                    height: 120px;
                }
            }

            .top-par {
                letter-spacing: 0.38px;
                color: #222222;
                font-size: 16px;
            }

            .number {
                letter-spacing: 0.24px;
                color: #AAAAAA;
                font-size: 10px;
            }

            .key-name {
                letter-spacing: 0.34px;
                color: #AAAAAA;
                font-size: 14px;
                margin-right: 10px;
            }

            .key-value {
                letter-spacing: 0.34px;
                color: #01141E;
                font-size: 14px;
                font-weight: 600;
            }

            .status-ongoing {
                letter-spacing: 0.34px;
                color: #DC880A;
                font-weight: 600;
                font-size: 14px;
            }

            .status-completed {
                letter-spacing: 0.34px;
                color: #0EC914;
                font-weight: 600;
                font-size: 14px;
            }

            .status-waiting {
                letter-spacing: 0.34px;
                font-weight: 600;
                font-size: 14px;
                color: #FF3E6C;
            }
        }

        .right {
            .placed-date {
                letter-spacing: 0.34px;
                color: #AAAAAA;
                font-size: 14px;
                margin: 5px 20px 0 0;
            }

            .onGoing_btnMain {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

            }

            .ongoing-btn {
                button {
                    letter-spacing: 0.38px;
                    background: #818181 0% 0% no-repeat padding-box;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: bold;
                    height: 38px;
                    margin-right: 20px;
                    padding: 0 25px;
                    border: none;
                }
            }
            .completed-btn.dark-color{
                button{
                    background: #818181 0% 0% no-repeat padding-box;
                    color: #FFFFFF;
                }
                
            }
            .completed-btn {
                button {
                    letter-spacing: 0.38px;
                    color: #818181;
                    font-size: 16px;
                    font-weight: bold;
                    height: 38px;
                    margin-right: 20px;
                    padding: 0 25px;
                    border: 1px solid #707070;
                    background-color: transparent;

                    // &:hover {
                    //     background: #818181 0% 0% no-repeat padding-box;
                    //     transition: all 0.5s;
                    //     color: #FFFFFF;

                    // }
                }
            }
        }

    }
}


// Order Details CSS

.main-order-details {
    padding: 30px 110px;
    background: #F7F7F7 0% 0% no-repeat padding-box;

    .order-box {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #00000008;
        margin-bottom: 15px;
        position: relative;
    }

    .summery-heading-top {
        border-bottom: 1px solid #EEEEEE;

        label {
            font-size: 16px;
            letter-spacing: 0.16px;
            color: #222222;
            padding: 15px 30px;
        }
    }

    .inner-content {
        display: flex;
        justify-content: space-between;
        padding: 30px;
    }

    .inner-content-stepper {
        padding: 30px;

        .step {

            padding: 10px;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;

        }

        .v-stepper {
            position: relative;
            /*   visibility: visible; */
        }


        /* regular step */
        .step .circle {
            background-color: white;
            border: 3px solid #E7E7E7;
            border-radius: 100%;
            width: 20px;
            /* +6 for border */
            height: 20px;
            display: inline-block;

        }

        .step .circle.remaining {
            background-color: #E7E7E7;

        }

        .step .line {
            // top: 20px;
            left: 9px;
            bottom: 48px;
            /*   height: 120px; */
            height: 100%;

            position: absolute;
            border-left: 3px solid #E7E7E7;
        }


        .step.active .line {
            left: 9px;
            bottom: 28px;
            /*   height: 120px; */
            height: 48px;

            position: absolute;
            border-left: 3px solid #E7E7E7;
        }

        .step.completed .circle {
            visibility: visible;
            border-color: #FF3E6C;
            // background-color: #FF3E6C;
        }

        .step.completed .line {
            border-left: 3px solid #FF3E6C;
        }

        .step.active .circle {
            visibility: visible;
            border-color: #E7E7E7
        }

        .step.active .line {
            border-left: 3px solid #E7E7E7 !important;
        }

        .step.empty .circle {
            visibility: hidden;
        }

        .step.empty .line {
            /*     visibility: hidden; */
            /*   height: 150%; */
            top: 0;
            height: 150%;
        }


        .step:last-child .line {
            border-left: 3px solid white;
            z-index: 0;
            /* behind the circle to completely hide */
        }

        .content {
            p {
                letter-spacing: 0.38px;
                color: #222222;
                font-size: 16px;
            }

            label {
                letter-spacing: 0.29px;
                color: #777777;
                font-size: 12px;
            }

            margin-left: 20px;
            display: inline-block;
        }


    }

    .left-content {
        display: flex;
        align-items: center;

        .image {
            margin-right: 15px;

            img {
                width: 120px;
                height: 120px;
            }
        }

        .top-par {
            letter-spacing: 0.38px;
            color: #222222;
            font-size: 16px;
        }

        .number {
            letter-spacing: 0.24px;
            color: #AAAAAA;
            font-size: 10px;
        }

        .key-name {
            letter-spacing: 0.34px;
            color: #AAAAAA;
            font-size: 14px;
            margin-right: 10px;
        }

        .key-value {
            letter-spacing: 0.34px;
            color: #01141E;
            font-size: 14px;
            font-weight: 600;
        }


    }


    

}
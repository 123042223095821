


.get-quote-bg {
    background-color: #F7F7F7;
    position: relative;
    // display: flex;
    overflow-x: hidden;
    height: calc(100vh - 268px);
    padding: 30px 110px;
    align-items: center;
    justify-content: center;

    .inner-section-cart {
        padding: 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #00000008;

        .q-input {
            height: 38px;
            border: 1px solid #E7E7E7;
            margin-bottom: 20px;
        }

        .email-label {
            letter-spacing: 0.34px;
            color: #777777;
            font-size: 14px;
        }


        .quote-btn {
            border: none;
            font-size: 16px;
            font-weight: 600;
            height: 38px;
            letter-spacing: 0.16px;
            color: #FFFFFF;
            background: #818181 0% 0% no-repeat padding-box;
        }

        .message {
            height: 60px;
            resize: none;

        }


    }
}



.get-text {
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.get-text span {
    color: #FE0843;
}

.quote-img {
    width: 100%;

    max-width: 407px;
}

.img-layout{
    display: flex;
    justify-content: center;
    align-items: center;
}
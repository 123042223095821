@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@400;600;700&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  font-family: "Overpass", sans-serif;

  
  a {
    text-decoration: none;
  }

  p {
    margin-bottom: 0;
  }

  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow: auto !important;
}

.container {
  max-width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  overflow-x: hidden;
  position: relative;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  display: none;
  height: 5px;
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fe0843;
}

.login-btn-main-responsive {
  display: none;

  a {
    font-weight: 700;
    font-size: 16px;
    border-right: 1px solid #eeeeee;
    letter-spacing: 0.18px;
    color: #fe0843;
  }
}

.btn-gray-common {
  border: none;
  letter-spacing: 0.38px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  background: #198754 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0000000d;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
    transition: all 0.5s;
  }
}

.heading-black {
  padding-top: 2px;
  letter-spacing: 0.58px;
  color: green;
  font-weight: 600;
  font-size: 18px;
}

.heading-red {
  letter-spacing: 0.58px;
  color: #198754;
  font-size: 18px;
  font-weight: bold;
}

.paragraph {
  // letter-spacing: 0.34px;
  // margin-top: 15px;
  color: #222222;
  font-size: 15px;
  // margin-bottom: 20px;
}

.mrc{
  width: 11rem
}
@media all and (max-width:768px) {
  .mrc{
    width: 9.6rem;
  }
  }

  .image_height_set{
    height:185px;
    // border-radius: 20px;
  }
  
  .mrc1{
   
    height: 144px;
  }
  a{
    color:  #fff !important
  }

  // .shopsectiontop{
  //   padding-top: 40px !important;
  // }
 
// ========== > Nave Section < ==========

.main-nav {
  height: auto;
  background-color: #ffffff;

  nav {
    .top-logo-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 110px;
      height: 75px;
      // background: linear-gradient(to right top, #000000, #1a6803, #102005, #075e0b, #00f10a);
      // background-color: #102005;

    }

    .img_round27{
      width: 60%;
    }
    .img_round_245{
      border-radius: 25px;
    }

    .right-section {
      display: flex;
      align-items: center;

      .contact-main {
        img {
          vertical-align: middle;
          margin-right: 10px;
        }

        label {
          font-size: 14px;
          letter-spacing: 0.14px;
          color: #222222;
        }

        .contact-number {
          padding: 0 20px;
          border-right: 1px solid #eeeeee;
          border-left: 1px solid #eeeeee;
        }

        .contact-mail {
          padding: 0 20px;
          border-right: 1px solid #eeeeee;
        }
      }

      .login-btn-main {
        a {
          font-weight: 700;
          font-size: 18px;
          padding: 0 20px;
          // border-right: 1px solid #eeeeee;
          letter-spacing: 0.18px;
          color: #fe0843;
        }
      }
    }

    .hide-ac-cart {
      display: none !important;
    }

    .nav-cart-account-logo {
      display: flex;

      .cart {
        position: relative;

        a {
          img {
            height: 44px;
            width: 44px;
          }
        }
      }

      .cart-badge {
        top: -7px;
        right: -7px;
        position: absolute;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: #fe0843;

        label {
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.34px;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
      }

      .account {
        margin-left: 20px;

        a {
          img {
            height: 44px;
            width: 44px;
            border-radius: 50%;
          }
        }
      }

      .bell {
        margin: 0 20px;

        a {
          img {
            height: 44px;
            width: 44px;
            border-radius: 50%;
          }
        }
      }
    }

    //  ==== Menu Style ====

    .menu-section {
      border-top: 1px solid #eeeeee;
      padding: 0 110px;
      height: 50px;
    background-color: green;

      ul {
        li {
          a {
            letter-spacing: 0.16px;
            color: #222222;
            font-size: 16px;
            margin-right: 60px !important;
          }
        }
      }

      .navbar {
      }

      .nav-active {
        color: red;
        font-weight: bold;
        border-bottom: 2px solid #222222;
      }
    }

    .route-bar-main {
      border-top: 1px solid #eeeeee;
      height: 50px;
      padding: 0 110px;
      display: flex;
      align-items: center;

      label {
        margin-left: 10px;
        letter-spacing: 0.34px;
        color: #aaaaaa;
        font-size: 14px;
      }
    }
  }
}




//====== Home page =====
.sliding-image {
  position: relative;

  .btn-on-slide {
    z-index: 10;
    position: absolute;
    left: 113px;
    bottom: 70px;

    a {
      display: inline-block;
      background: #818181 0% 0% no-repeat padding-box;
      padding: 15px 30px;
      letter-spacing: 0.38px;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;

      &:hover {
        transition: all 0.5s;

        box-shadow: #fe0843 0px 0px 15px;
      }
    }
  }


  // background-image: url('../images/banner_slide_01.png');

  // overflow: hidden;
  .img-slide-inner {
    // background-image: url('../images/banner_slide_01.png');
  }

  .slick-next:before {
    display: none;
  }

  .slick-prev:before {
    display: none;
  }

  .slick-dots {
    display: flex !important;
    flex-direction: column;
    top: 50% !important;
    transform: translateY(-50%) !important;
    bottom: unset !important;
  }

  @media all and (max-width:768px) {
    .slick-dots {
      display: flex !important;
      flex-direction: column;
      top: 35% !important;
      transform: translateY(-50%) !important;
      bottom: unset !important;
    }
    }
  

  .slick-dots li button:before {
    opacity: 0.1;
    content: "" !important;
    display: block !important;
    width: 3px !important;
    height: 53px;
    background: #222222 !important;
    left: 50px;
    bottom: 235px !important;
    position: absolute;
  }

  .slick-dots li.slick-active button:before {
    background-color: #fe0843 !important;
    opacity: 1 !important;
  }

  .slick-dots li {
    height: 53px;
    border-radius: 50%;
  }
}

.home-main-section {
  .sliding-image {
    // height: 449px;

    img {
      // object-fit: contain;
      width: 100%;
      // height: 340px !important;
      margin-top: 1px;
      max-height: 300px;
      min-height: 6rem;
    padding-top: 10px;

    }
  }

  .main-below-slide {
    .bckground-color-about {
      background-color: #f7f7f7;
      height: auto;
    }

    .about-section {
      padding: 70px 110px;

      display: flex;
      height: auto;

      .left-about {
        margin-right: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading-black {
          letter-spacing: 0.58px;
          color: #222222;
          font-weight: 600;
          font-size: 24px;
        }

        .heading-red {
          letter-spacing: 0.58px;
          color: #fe0843;
          font-size: 24px;
          font-weight: bold;
        }

        .about-paragraph {
          letter-spacing: 0.34px;
          margin-top: 15px;
          color: #777777;
          font-size: 14px;
        }

        button {
          height: 52px;
          padding: 0 32px;
          margin-top: 30px;
        }
      }

     

      .right-about {
        display: flex;
        align-items: center;

        .right-img-main {
          position: relative;
          height: 350px;
          width: 350px;

          .main-img {
            img {
              height: 350px;
              width: 350px;
            }

            z-index: 200;
            position: absolute;
          }

          .graphics-img {
            position: absolute;
            width: 112px;
            height: 157px;
            z-index: 120;
            right: -18px;
            bottom: -16px;
          }

          .img-dsn-top {
            position: absolute;
            top: -10px;
            left: -10px;
            width: 155px;
            height: 281px;
            background-color: #f9cbd5;
          }
        }
      }
    }

    .bckground-color-offer {
      height: 653px;
      background-color: #ffffff;

      .slick-next:before {
        content: url(../images/slide_next.svg) !important;
      }

      .slick-prev:before {
        content: url(../images/slide_prev.svg) !important;
      }

      .offer-section {
        padding: 70px 110px;

        .slick-prev {
          left: -50px;
        }
        .slick-next {
          right: -50px;
        }
        .slick-prev,
        .slick-next {
          top: 150px;
        }
        .slick-list {
          // overflow:visible !important;
        }

        .top-offer {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            .heading-black {
              letter-spacing: 0.58px;
              color: #222222;
              font-weight: 600;
              font-size: 24px;
            }

            .paragraph {
              letter-spacing: 0.34px;
              color: #777777;
              font-size: 14px;
            }
          }

          .right {
            button {
              height: 48px;
              width: 125px;
            }
          }
        }
      }

      .slide-img-main {
        outline: none !important;
        z-index: 310;
        position: relative;

        .date-on-img {
          top: 20px;
          left: 40px;
          position: absolute;
          z-index: 410;
          background-color: #fe7092;
          height: 64px;
          width: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.48px;
          color: #ffffff;
        }

        .discount-card {
          max-width: 293px;
          position: absolute;
          top: 80px;
          right: 50px;
          z-index: 410;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 4px 16px #0000001a;
          padding: 35px;
          .paragraph {
            margin-bottom: unset;
          }
          .red-line {
            position: absolute;
            width: 5px;
            height: 49px;
            background-color: #fe0843;
            left: 0;
            bottom: 164px;
          }

          .steel-name {
            letter-spacing: 0.16px;
            color: #222222;
            font-size: 16px;
          }

          .percent-content {
            letter-spacing: 0.6px;
            color: #222222;
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px;
          }

          .paragraph {
            margin-top: 10px;
            letter-spacing: 0.42px;
            color: #777777;
            font-size: 14px;
          }

          button {
            width: 100%;
            border: none;
            background: #818181 0% 0% no-repeat padding-box;
            height: 44px;
            letter-spacing: 0.38px;
            color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            margin-top: 30px;
          }
        }
      }




      .slick-prev,
      .slick-next {
        z-index: 120;

        height: auto !important;
        width: auto !important;
      }

      // .slick-slider {
      //     height: 299px;
      // }

      .slick-slide img {
        padding: 0 20px;
        width: 100%;
        height: 300px;
      }

      .slick-prev:before,
      .slick-next:before {
        opacity: 1 !important;
      }
    }

    .bckground-color-shop {
      .tab-content {
      }

      // display: flex;
      align-items: center;
      // height: 686px;
      background-color: #fff;
      // background-color: #fff;

      .shop-section {
        ::-webkit-scrollbar {
          display: block !important;
        }

        .top-heading {
        }

        padding: 10px 58px;

        .view-all {
          button {
            height: 48px;
            width: 125px;
          }
        }

        .main-tab-card {
          display: flex;
          justify-content: space-between;

          .product-card {
            overflow-x: auto;

            .right-card-top {
              margin-bottom: 15px;
              display: flex;
              justify-content: space-between;

              .left-input {
                display: flex;
                justify-content: space-between;
              }

              .search {
                .form-control {
                  box-shadow: 0px 4px 16px #0000000d;
                  border: none;

                  border-radius: unset;
                  height: 48px;
                  width: 300px;
                  letter-spacing: 0.38px;
                  color: #222222;
                  font-size: 16px;
                  background-image: url(../images/ic_search_input.svg);
                  background-repeat: no-repeat;
                  background-position: right 0.75rem center;
                  background-size: 18px 18px;

                  &::placeholder {
                    letter-spacing: 0.32px;
                    font-size: 16px;
                    color: #aaaaaa;
                  }
                }
              }

              .select {
                .form-select {
                  border-radius: unset;
                  box-shadow: 0px 4px 16px #0000000d;
                  border: none;
                  height: 48px;
                  width: 300px;
                  letter-spacing: 0.38px;
                  color: #222222;
                  font-size: 16px;
                }
              }
            }
          }

          .tab {
            // margin-right: 30px;
          }

          .nav-link.active {
            background-color: #ff3e6c;
            color: #ffffff;
          }

          .nav-link.active::after {
            content: url(../images/arrow_highlight.svg);
            position: absolute;
            right: -8px;
          }

          .nav-link {
            position: relative;
            letter-spacing: 0.48px;
            color: #777777;
            font-weight: 600;
            font-size: 20px;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 16px #0000000d;
            margin-bottom: 20px;
            // padding: 18px 120px 18px 20px;
            width: 250px;
            padding: 18px;
            text-align: left;
          }

          .main-hot-rolled {
            display: flex;
            margin-bottom: 10px;

            .tab-card {
              background: #ffffff 0% 0% no-repeat padding-box;
              box-shadow: 0px 4px 16px #0000000d;

              .image {
                height: 228px;
              }

              .product-detail {
                position: relative;
                padding: 20px;

                .detail-top {
                  letter-spacing: 0.38px;
                  color: #222222;
                  font-size: 16px;
                }

                .number {
                  letter-spacing: 0.24px;
                  color: #aaaaaa;
                  font-size: 10px;
                }

                .key-name {
                  letter-spacing: 0.34px;
                  color: #aaaaaa;
                  font-size: 14px;
                  margin-right: 10px;
                }

                .key-value {
                  letter-spacing: 0.34px;
                  color: #01141e;
                  font-size: 14px;
                  font-weight: 600;
                }

                .amt-add-card-main {
                  margin-top: 20px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .like-img img {
                    margin-left: 15px;
                  }

                  .price {
                    letter-spacing: 0.31px;
                    color: #01141e;
                    font-weight: 600;
                    font-size: 13px;
                  }
                }

                .red-line {
                  position: absolute;
                  width: 3px;
                  left: 0;
                  top: 20px;
                  height: 30px;
                  background: #fe0843 0% 0% no-repeat padding-box;
                }
              }
            }
          }
        }
      }
    }

    .bckground-color-popular-product {
      // display: flex;
      // align-items: center;
      height: auto;
      background-color: #ffffff;

      .view-all {
        button {
          height: 48px;
          width: 125px;
        }
      }

      .popular-product-section {
        ::-webkit-scrollbar {
          display: block !important;
        }

        .main-product {
          overflow-x: auto;
        }

        display: flex;
        padding: 70px 110px;
        justify-content: space-between;

        .heding-btn-left {
          width: calc(250px + 1rem);
        }
      }

      .popular-product-card {
        display: flex;
        margin-bottom: 10px;
      }
    }
  }
}

.tab-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0000000d;

  .image {
    height: 228px;
  }

  .product-detail {
    position: relative;
    padding: 20px;

    .detail-top {
      letter-spacing: 0.38px;
      color: #222222;
      font-size: 16px;
    }

    .number {
      letter-spacing: 0.24px;
      color: #aaaaaa;
      font-size: 10px;
    }

    .key-name {
      letter-spacing: 0.34px;
      color: #aaaaaa;
      font-size: 14px;
      margin-right: 10px;
    }

    .key-value {
      letter-spacing: 0.34px;
      color: #01141e;
      font-size: 14px;
      font-weight: 600;
    }

    .amt-add-card-main {
      .btn-gray-common {
        height: 37px;
        padding: 0 20px;
      }

      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .like-img img {
        margin-left: 15px;
      }

      .price {
        letter-spacing: 0.31px;
        color: #01141e;
        font-weight: 600;
        font-size: 13px;
      }
    }

    .red-line {
      position: absolute;
      width: 3px;
      left: 0;
      top: 20px;
      height: 30px;
      background: #fe0843 0% 0% no-repeat padding-box;
    }
  }
}

.main-footer {
  .top-div {
    padding: 0 110px;
    height: 38px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 6px #0000001c;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .term-policy {
      .term {
        padding-left: 15px;
        padding-right: 15px;
        border-left: 1px solid #e7e7e7;
        letter-spacing: 0.42px;
        color: #fe0843;
        font-size: 14px;
        font-weight: 600;
      }

      .policy {
        padding-right: 15px;
        letter-spacing: 0.42px;
        color: #fe0843;
        font-size: 14px;
        font-weight: 600;
      }

      .about {
        padding-left: 15px;
        border-left: 1px solid #e7e7e7;
        letter-spacing: 0.42px;
        color: #fe0843;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .bottom-div {
    background-color: #ffffff;

    .social-media-icons {
      a {
      }

      img {
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }
    }

    label {
      letter-spacing: 0.42px;
      color: #212121;
      font-size: 14px;
      font-weight: 600;
    }

    padding: 0 110px;

    height: 55px;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.background-how-it-works {
  img {
    width: 100%;
  }

  height: auto;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;

  .how-it-works-section {
    padding: 70px 110px;
  }

  .top-heading {
    text-align: center;
  }
}



.set_timer1{
  padding-left: 13px;
}
// ========= Product ========
.background-product {
  height: calc(100vh - 268px);
  overflow-y: auto;
  background: #f7f7f7 0% 0% no-repeat padding-box;
}

.main-product-page {
  padding: 30px 110px;

  ::-webkit-scrollbar {
    display: block !important;
  }

  .search-wrap {
    position: sticky;
    left: 0;
  }
  .main-card-listing {
    padding: 10px;
  }

  .product-section-page {
    display: flex;
    justify-content: space-between;
  }

  .left-catagory-main {
    margin-right: 30px;
    padding: 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000008;
    min-width: 250px;

    button {
      height: 52px;
      width: 100%;
      margin-top: 50px;
    }

    .heading-category {
      letter-spacing: 0.54px;
      color: #222222;
      font-size: 18px;
      font-weight: 600;
    }

    .check-category-main {
      margin-top: 15px;
      display: flex;
      align-items: center;

      label {
        letter-spacing: 0.42px;
        color: #222222;
        font-size: 14px;
      }
    }

    .form-check-input:checked[type="checkbox"] {
      background-image: url(../images/tick_checkbox.svg) !important;
      background-size: auto;
      box-shadow: none !important;
    }

    .form-check-input:checked {
      background-color: transparent !important;
      border: 1px solid #aaaaaa !important;
      box-shadow: none !important;
    }

    .form-check-input {
      background-color: transparent !important;
      border: 1px solid #aaaaaa !important;
      border-radius: 5px !important;
      box-shadow: none !important;
      height: 18px;
      width: 18px;
      margin-top: 0 !important;
    }
  }

  .right-card {
    .search-wrap {
      .search-btn-input {
        border: none;
        background-color: transparent;
        position: absolute;
        right: 20px;
        top: 13px;
      }
      input {
        padding: 0 20px;
        border: none;
        box-shadow: 0px 4px 16px #0000000d;
        height: 48px;

        letter-spacing: 0.38px;
        color: #222222;
        font-size: 16px;
        // background-image: url(../images/ic_search_input.svg);
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 18px 18px;
      }

      ::placeholder {
        letter-spacing: 0.32px;
        color: #aaaaaa;
        font-size: 16px;
      }
    }

    .product-cards {
      display: flex;
      justify-content: space-between;

      .tab-card {
        width: 415px;
        margin-top: 30px;
        margin-right: 30px;
      }
    }
  }
}

.offcanvas {
  height: 100vh;
  position: absolute;
  transition: 0.3s ease-in-out;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -4px 4px 16px #0000000d;

  .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/10px auto no-repeat;
  }
}

.offcanvas-end {
  width: 300px;
}

.offcanvas-header {
  justify-content: flex-end;
  padding: 10px;
}

.profile-detail-mg {
  text-align: center;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;

  img {
    height: 90px;
    width: 90px;
    border-radius: 50%;
  }

  .u-name {
    letter-spacing: 0.48px;
    color: #222222;
    font-size: 16px;
    margin-top: 5px;
  }

  .u-email {
    letter-spacing: 0.42px;
    color: #777777;
    font-size: 14px;
    margin-top: 2px;
  }
}

.offcanvas-body {
  padding: 0 20px 20px 20px;

  .account-list {
    padding-top: 20px;

    .ac-icon {
      padding-bottom: 18px;

      img {
        height: 16px;
        width: 16px;
      }
    }

    a {
      text-decoration: none;
      display: block;
      width: 100%;
    }

    display: flex;
    align-items: center;

    .inner-ac-list {
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      margin-left: 10px;

      .ac-list-name {
        p {
          letter-spacing: 0.34px;
          color: #222222;
          font-size: 14px;
        }
      }

      .arrow-icon {
        img {
          height: 8px;
          width: 8px;
        }
      }
    }
  }
}

.fix-call-icon {
  cursor: pointer;

  img {
    height: 26px;
    width: 26px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  background: #198754 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #777777;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  z-index: 5114;
  position: fixed;
  right: 30px;
  top: calc(100vh - 15%);
}

#HomeShopByCategoryMain {
  width: 517px;
  ::-webkit-scrollbar {
  }
  .left-catagory-main {
    height: 421px;
    overflow-y: auto;
    margin-right: unset;
    width: 290px;
  }

  padding: unset;
  .search-btn-input {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 20px;
    top: 10px;
    
  }

  
  .search-wrap input {
    padding: 0 20px;
    border: none;
    // box-shadow: 0px 4px 16px rgb(0 0 0 / 5%);
    height: 40px;
    letter-spacing: 0.38px;
    color: #222222;
    font-size: 16px;
    // background-image: url(../images/ic_search_input.svg) !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 18px 18px;
    // margin-bottom: 20px;
    border: solid 1px #777777;
    
  }

  .right-card .product-cards .tab-card {
    margin-top: unset;
    margin-right: unset;
    margin-left: 30px;
  }

  .btn-gray-common {
    height: 37px;
    padding: 0 20px;
  }
}

.btn-shop-home {

  line-height: 2.2 !important;
  padding: 5px;
  // margin-top: 20px;
  text-align: right;
  .btn-gray-common {
    height: 48px;
    width: 125px;
    border-radius: 20px;
  }
}

btn-shop-home1{
  // margin-top: 20px;
  text-align: right;

  .btn-gray-common1 {
    height: 48px;
    width: 125px;
    border-radius: 20px;
  }
}

.main-howWeWork {
  overflow-y: auto;
  height: calc(100vh - 283px);
  background: #f7f7f7 0% 0% no-repeat padding-box;

  .img-how-work {
    padding: 30px 110px;
    img {
      width: 100%;
      max-width: 798px;
    }
  }
  #img-style {
    padding: 30px 110px;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      max-width: 798px;
    }
  }
}

.TermsConditions-main {
  h3 {
    letter-spacing: 0.84px;
    color: #222222;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }

  .TermsConditions-para {
    p {
      letter-spacing: 0.16px;
      color: #777777;
      font-size: 16px;
      text-align: justify;
    }
  }
}

@media all and (min-width:1166px) {
  .set_search_box2{
      display: none;
  }
  }

.set_search_box1{
  display: inline-flex;
}

.colerschange{
  color: white !important;
  padding: 5px;
}

.colerschange1{
  color: green !important;
  padding: 5px;
}



.slider.variable-width {
}

.flex-0{
  flex: 0 0 0% !important;
}

.pad5{
  padding: 5px;
}

.posfix{
  position: fixed;
}

.posabs{
  position: absolute;
}

.posinh{
  position: inherit;
}

.width100{
  width: 100%;
}

.topnavset{
  height: 40px;
  background-color: black;
}
.topnavset1{
  color: #fff;
  // font-weight: bold;
  font-size: 14px;
  
}
.topnavset1{
  padding-top: 9px;
  
}


.topnavset3{
  background-color:green;
  height: 40px;
}
.topnavset4{
  width: 100px;
}

.topnavset6{
  background-color:green;
  // width: 100%;
  color: white;
  text-align: right;
  padding-top: 1px;
  border: none;
  font-weight: bold;

}
.topnavset6:focus-visible{
  border: none;
}

.topnavset7{
  padding-top: 10px;
}

.topnavset8{
  padding-top: 10px;
  text-align: center;
  font-size: 15px;
  
}

.topnavset9{
  border: solid 1px #eeeeee;
}

@media all and (max-width:768px) {
  .topnavset8 {
    // padding: 10px;
    font-size: xx-small;
    padding-top: 5px;
      }
  }


@media all and (max-width:768px) {
  .topnavset1 {
    // padding: 10px;
    font-size: xx-small;
    // padding-top: 5px;
      }
  }
  @media all and (max-width:768px) {
    .topnavset2 {
      padding: 8px;
        }
    }

  @media all and (max-width:768px) {
    .topnavset3 {
      background-color:green;
      height: 65px;
    
        }
    }
  
.topnavset4{
  color: black;
  font-size:15px;
  margin-left: 10px;
  width: 88px;
}

.topnavset5{
  color: black;
  font-size:15px;
}

.setseed{
  text-align:-webkit-center
}

.webkitCenter{
  text-align:-webkit-center !important;
}

.workDiv{
  text-align:-webkit-center !important;
}


.imghw{
  width: 100%;
  max-height: 280px;
  max-width: 280px;
}

.foothead{
  font-size: 13px;
  color: #198754;
  letter-spacing: 0.12px;
}

.footparagraph{
  font-size: 11px;
}

.hwdiv{
  padding: 5px;
  border: 1px solid rgba(0,0,0,.125);
  // height: inherit;
}

.hw{
  text-align: left;
  padding-top: 10px;
}

.cat_title{
  padding: 12px;
}
.cat_title1{
  padding: 13px;

}
.changecolorpaymenttext{
  color: black;
}

.slick-prev:before,
.slick-next:before {
  color: #198754 !important;
}

.setloader{
  text-align: center;
  color: red;
}


/* styles.css */
.rating-container {
  display: flex;
  align-items: center;
  font-size: 13px;

}

.rating-container1 {
  background-color: var(--bs-orange);
  color: white;
  padding: 5px;
  font-weight: bold !important;

}



.rating-container .btn {
  margin-right: 8px; /* Adjust the spacing between the rating and stars */
}

.star-rating .fa-star,
.star-rating .fa-star-half-alt,
.star-rating .fa-star-empty {
  color: var(--bs-orange);
  margin-right: 2px;
}

.text_settag{
  background-color: #e72235;
  color: white;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 8px;
}

.modal-backdrop{
  z-index: 0 !important;
}

.set-report-text{
  font-size: 10px;
}
.set-report-button{
  text-align: center;
}

.ecom_detail_list_changes{
  color: #FFA534;
}
.calltoorder{
  width: 339px;
  font-weight: bold;
  color: white;
  background-color: var(--bs-orange);
  border: var(--bs-orange);
  border-radius: 5px;
}

.topnavset12 {
  color: black;
  font-size: 6px;
  margin-left: 10px;
  width: 26px;
}
.mrc2{
  position: absolute;
}

.btn-warning {
  color: var(--toastify-color-light);
  background-color: var(--bs-orange);
  border-color: #ffc107;
}


.offers-bg {

    background-color: #F7F7F7;
    position: relative;
    overflow-x: hidden;
    height: calc(100vh - 268px);
    padding: 30px 110px;
    align-items: center;
    justify-content: center;

    .inner-card-section {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #00000008;
        height: auto;
        padding: 20px;


        .offer-card-inner {
            display: flex;
        }

        .offer-card {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .img-card {
            margin-right: 20px;
        }
    }
}

.offers-bg1 {

    background-color: #F7F7F7;
    position: relative;
    overflow-x: hidden;
    height: calc(120vh - 268px);
    // padding: 30px 110px;
    align-items: center;
    justify-content: center;

}

@media all and (max-width:768px) {
    .offers-bg1 {

        background-color: #F7F7F7;
        position: relative;
        overflow-x: hidden;
        height: calc(90vh - 268px);
        // padding: 30px 110px;
        align-items: center;
        justify-content: center;
    
    }
    
}

.shadowcard {
    box-shadow: 0 0px 2px 0 #aaaaaa, 0 0px 0px 0 #ffffff;
    margin-bottom: 10px;
    border: 0px !important;
    border-radius: 9px;
}

.img_round {
    // border-radius: 20px;
    // box-shadow: 0 5px 8px 0 #aaaaaa, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 135px;
    // border: solid 2px gold;
}

@media all and (max-width:768px) {
    .img_round {
        width: 90px;
      
        }
    }



.img_round1 {
    box-shadow: 3px 21px 11px 4px rgba(25, 135, 84, 0.6588235294), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 166px;
    border: solid 7px gold;
}

.img_round2 {
    // box-shadow: 3px 21px 11px 4px rgba(25, 135, 84, 0.6588235294), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 148px;
    border: solid 3px #ffc107;
    height: 130px;
    border-radius: 10px;
    padding: 10px;
}

@media all and (max-width:768px) {
    .img_round2 {
    width: 107px;
    border: solid 3px #ffc107;
    height: 101px;
    border-radius: 10px;
    padding: 10px;

        }
    }




.img_round3 {
    max-height: 200px;
    min-height: 200px;
    width: 96%;
    border-radius: 20px;
    
}

@media all and (max-width:768px) {
    .img_round3 {
        // max-height: 151px;
        width: 100%;
        padding-top: 8px;
        max-height: 200px;
        min-height: 151px;

        }
    }



.img_round51{
    height: 200px;
    border-radius: 20px;

}
@media all and (max-width:768px) {
    .img_round51 {
        width: 100%;
        height: 200px;
        border-radius: 20px;
        }
    }

.img_round4 {
    width: 100%;
    max-height: 350px;
    border-radius: 20px;
    padding-top: 10px;
}

.mrq{
    font-size: 26px;
    color: #102005;

}   


.cupon{
    border: 1px solid green;
    height: 100%;
    // background-color: #f7f7f7;
    
}


.btnsett{
    width: 100% !important;
}

.mouse1{
    font-weight: bold;
    font-size: 18px;
    // color: #fff;
    // background-color: #198754;
    width: 100%;
    height: 50px;
    padding: 12px;

}

.mouse{
    font-size: 14px;
}
.cupon1{
    padding-top: 36px;
    padding-left: 27px;
    padding-right: 27px;
    
}

.img_round4 {
    // box-shadow: 3px 11px 11px 4px rgba(25, 135, 84, 0.6588235294), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // height: 450px;
    width: 100%;
}

// .fixcardhww1{
// width: 18%
// }

.tac{
    text-align: center;
    padding-top: 10px;
    width: 100%;
}
.sbbpt{
    padding-top: 5px;
}

.img_round5 {
    padding: 5px;
    height: 380px;
    width: 100%;
    // padding-top: 8px;
    border-radius: 20px;

}

.img_round50 {
    border-radius: 20px;
    box-shadow: 3px 21px 11px 4px rgba(25, 135, 84, 0.6588235294), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 183px;
    border: solid 1px;
}

.img_round6 {
    box-shadow: 3px 11px 11px 4px rgba(25, 135, 84, 0.6588235294), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 300px;
    width: 100%;
}

.img_round7 {
    // height: 120px;
    width: 50%;
}

.img_round8 {
    // box-shadow: 3px 11px 11px 4px rgba(25, 135, 84, 0.6588235294), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 160px;
    height: 150px;
    border: solid 3px #198754;
    // border-image: linear-gradient(to right, red 20%, #fff 20%, #fff 40%, #f8f9fa 40%, #f7f7f7 60%, #fff 60%, #fff 80%, chocolate 80%);
    border-image-slice: 1;
    border-radius: 25px;
    margin: 5px;
}

@media all and (max-width:768px) {
    .img_round8 {
        // box-shadow: 3px 11px 11px 4px rgba(25, 135, 84, 0.6588235294), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        width: 131px;
        height: 125px;
        border: solid 4px #198754;
        border-image-slice: 1;
        border-radius: 25px;
    }
}
.tc-10{
    font-size: 20px;
}




@media all and (max-width:768px) {
    .img_round4 {
        // max-height: 151px;
        width: 100%;
        padding-top: 8px;
        max-height: 200px;
        min-height: 151px;

        }
    }
.img_round52{
    height: 200px;
    width: 96%;
    border-radius: 20px;
}

@media all and (max-width:768px) {
    .img_round52 {
        height: 87px;
        // width: 96%;
        border-radius: 9px;
        }
    }

@media all and (max-width:768px) {
    .img_round50 {
        height: 125px;
        width: 100%;
        padding-top: 8px;
        border-radius: 20px;
        }
    }

@media all and (max-width:768px) {
    .img_round24 {
       width: 57px;
    }
}

.img_round9{
    width: 100%;
}    

.img_round13{
    width: 22px;

}

.img_round15{
    width: 60px;
}

.pl10px{
    padding-left: 10px;
}

.img_round17{
    width: 79px;
    padding-top: 14px;
}
.setpay{
    font-size: 14px;
    font-family: cursive;


}
.img_round19{
    width: 60px;
}
.img_round20{
    width:  38px;

}
.e_set{
    

}

.image-round53{
    width: 100%;
    height: 210px;
    // box-shadow: 3px 11px 11px 4px rgba(25, 135, 84, 0.6588235294), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* border: dotted 6px green; */
    border-radius: 10px;
    border: solid 7px gold;
}

@media all and (max-width:768px) {
    .image-round53{
        width: 100%;
        height: 205px;
        // border: dotted 6px green;
        // border-radius: 41px;
    }
}


.image-round54{
    width: 100%;
    height: 210px;
    // box-shadow: 3px 11px 11px 4px rgba(25, 135, 84, 0.6588235294), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* border: dotted 6px green; */
    border-radius: 10px;
    border: solid 7px gold;
}

@media all and (max-width:768px) {
    .image-round54{
        width: 100%;
        height: 205px;
        // border: dotted 6px green;
        // border-radius: 41px;
    }
}

@media all and (max-width:768px) {
    .heading-top{
        padding-top: 20px;
      
    }
}

.heading-top{
    padding-top: 20px;
    // padding-left: 284px;
}



.heading-top2{
    letter-spacing: 0.34px;
    margin-top: 15px;
    color: green;
    font-size: 13px;
    margin-bottom: 40px;


}

.heading-top1{
    padding-top: 3px;
    color: #198754;
}
.pat{
    padding-top: 50px;

}

.font-set_wait{
    padding-top: 20px;
    font-size: 13px;
    font-weight: bold;
    // padding-left: 431px;

}

@media all and (max-width:768px) {
    .font-set_wait{
    padding-top: 20px;
    font-size: 15px;
    font-weight: bold;

      
    }
}

.heading-top-span{
    font-size: 13px;
}

.img_round22{
    width: 100%;
    // padding-top: 40px;
}

.img_round23{
    width: 8px;
}

.img_round32{
    width: 125px;
}
.img_round34{
    width: 114px;
}

.order_place-button{
    width: 100%;
}
// .sbccc {
//     background: linear-gradient(90deg, rgb(56 255 2 / 26%) 0%, rgba(15, 255, 1, 0.31976540616246496) 100%, rgba(56, 255, 0, 0.42620798319327735) 100%);
// }


@media all and (max-width:768px) {
    .img_round32 {
        width: 100%;
    } 
}

@media all and (max-width:768px) {
    .mt1-10 {
        padding-top: 10px;
    } 
}

@media all and (max-width:768px) {
    .img_round34 {
        width: 100%;
    } 
}

@media all and (max-width:768px) {
    .right {
       padding-top: 30px;
    } 
}

.text_set{
  padding-top:5px;
  width: 100%;
  /* border: 1px solid red; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  
}
.text_set1{
    font-weight: 600;
    font-size: 13px;
    color: green;
}


.mrc2{
    // padding-left: 88px;
}

.mrc3{
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    border-radius: 10px 0px;
}

.text_set3{
    color: #777777;
    /* border: 1px solid red; */
   
  }


  
.text_set2{
    font-size: 15px;
    font-weight: 600;
}


.img-card {
    img {
        height: 160px;
        width: 180px;
    }

}    

.set_view{
    border: solid 1px;
    padding-top: 10px;
}

.form_set{
    padding-top: 10px;
}
.btnfix1{
    padding-top: 10px;
    width: 100%;
}
.fon_size_fix{
    font-size: 13px;
}

.card-title {
    color: #198754;
    font-size: 16px;
    letter-spacing: 0.12px;
}

@media all and (max-width:768px) {
    .card-title {
        color: #102005;
        font-size: 12px;
        letter-spacing: 0.12px;
    }
    }

.first-purchase {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.48px;
    color: #222222;
}

.card-text {
    font-size: 13px;
    letter-spacing: 0.36px;
    color: #212529;

}   

.ccf{
    color: green;
}

.table_c_c{
    background-color: antiquewhite;
}

.pal{
    padding-left: 166px;
} 

.table_formate{
    border: solid 1px gray;
    padding-top: 10px;
    /* text-align: center; */
    font-size: 13px;
    padding-left: 10px;    

}
.table_formate1{
    padding-left: 246px;
}

@media all and (max-width:768px) {
    .table_formate1 {
        padding-left: 119px;
    }


}
.text_color{
    color: #102005;
    font-weight: bold;
    font-size: 18px;
}



.outer-wrapper {
    margin: 10px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid black;
    border-radius: 4px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.9);
    // max-width: fit-content;
    max-height: fit-content;

    
}
.table-wrapper {

    overflow-y: auto;
    overflow-x: auto;
    height: fit-content;
    max-height: 55.4vh;
    
    margin-top: 22px;
    
    margin: 15px;
    padding-bottom: 20px;
    
}


table {
    
    min-width: max-content;
    
    border-collapse: separate;
    border-spacing: 0px;    
    width: 100%;
    
}

.img_round_set_new_slide{
    max-height: 330px;
    min-height: 6rem;
    width: 100%;
    border-radius: 20px;

}
.img_round_set_new_slide1{
    border-radius: 20px;
}

table th{
    position: sticky; 
    top: 0px;
    
    background-color: #133b5c;
    color: #fff;
    
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    outline: 0.7px solid black;
    border: 1.5px solid black;
    

} 



table th, table td {
    
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    
}

table td {
    text-align: left;
    
    font-size: 15px;
    border: 1px solid rgb(177, 177, 177);
    padding-left: 20px;
    
}
  
  th, td {
    text-align: left;
    padding: 8px;
  }


.header_color_change{
    background-color: #198754;
    color: #fff;
}

.exp-date {
    color: #777777;
    font-size: 14px;
    letter-spacing: 0.42px;
    text-align: justify;
}

.current-date {
    margin-left: 5px;
    letter-spacing: 0.42px;
    color: #222222;
    font-weight: bold;
    font-size: 14px
}

.grab-btn {
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    height: 38px;
    width: 163px;
    background: var(--unnamed-color-818181) 0% 0% no-repeat padding-box;
    background: #198754 0% 0% no-repeat padding-box;
}



@media all and (max-width:768px) {
    .fontset1 {
        font-size: 10px;
    }
}

@media all and (max-width:768px) {
    .fontset2 {
        height: 250px;
       

    }


}


@media all and (max-width:768px) {
    .offer-card {
        height: auto;
    }


}

@media all and (max-width:600px) {
    .grab-btn {}
}

@media all and (max-width:480px) {
    .grab-btn {}
}
.latest-offer{
    padding-top: 10px;
}
.pad6{
    border: solid 2px gray;
}
.bs1{
    overflow-x: inherit;
}


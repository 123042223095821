.paginationBttns {
    list-style: none;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
  }
  
  .paginationBttns a {
    padding: 6px 10px 6px 10px;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #53B7E8;
    color: #53B7E8 !important;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .paginationBttns a:hover {
    color: white !important;
    background-color: #53B7E8;
  }
  
  .paginationActive a {
    color: white !important;
    background-color: #53B7E8;
  }
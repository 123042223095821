.details-lists-main {

  .bdr-btm {
    border-bottom: 1px solid #EEEEEE;

    .over-text {
      padding: 15px !important;
    }

  }

  .pts{
    padding-top: 5px;
  }

  .details-bg {
    background-color: #F7F7F7;
    padding: 10px 58px;

    .inner-section-cart {
      padding: 20px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 16px #00000008;
    }

    .heart-divide {
      display: flex;
      justify-content: space-between;
    }

    .top-heading-heart {


      .over-text {
        padding: 5px 10px;
      }

      .padding-box {

        .detail-para {
          letter-spacing: 0.16px;
          color: #777777;
          font-size: 16px;
        }

        padding: 20px;
        padding-top: 5px;
        

        .item-section {
          margin-top: 15px;
        }

        .heading-p-i {
          letter-spacing: 0.84px;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }

    .input-details {
      margin-bottom: 20px;
      border: 1px solid #E7E7E7;
      height: 38px;
    }

    .right-form {
      label {
        letter-spacing: 0.38px;
        color: #222222 !important;
        font-size: 16px;
        padding-top: 5px;
      }
    }

    textarea {
      resize: none;
      height: 72px !important;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    display: none;
    line-height: 1;

    color: none !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .custom-dots {
    bottom: -100px;
  }

  .custom-dots li {
    width: 64px;
    height: 64px;
  }

  .slick-dots li.slick-active {
    border: 2px solid #FE0843;
  }

  .slick-dots {
    position: unset;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .detail-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000008;
  }

  .detail-input {
    width: 100%;
  }

  .other-input {
    width: 100%;
    height: 59px;
    border: 1px solid #E7E7E7;

  }
  .bsibato{
    border: 4px dotted green;

  }
  .addcart-btn {
    border: none;
    color: white;
    width: 180px;
    height: 42px;
    border: 1px solid var(--unnamed-color-818181);
    border: 1px solid #818181;
    font-size: 16px;
    background-color: #198754;
  }

  .getquote-btn1 {
    width: 160px;
    height: 42px;
    background: var(--unnamed-color-818181) 0% 0% no-repeat padding-box;
    background: #fd7e14 0% 0% no-repeat padding-box;
    color: #fff;
    border: none;
    font-weight: bold;

  }

  .form-select {
    border: 1px solid #E7E7E7;
    border-radius: 1px !important;

  }

  .btn-detalil-products {
    text-align: center;
    // float: right;
    padding-top: 10px;
    // position: absolute;
    // bottom: 0;
    // right: 20px;
  }

  .btn-detalil-products_one{
    text-align: right;
    // float: right;
    padding-top: 10px;
  }

  .right-col{
    position: relative;
  }

  .hot-text {
    // margin-bottom: 20px;

    p {
      font-size: 24px;
      letter-spacing: 0.58px;
      color: #222222;
      font-weight: 600;
    }

  }

  .steel-text {
    color: blac !important;
    font-size: 14px !important;
  }

  .thick-label {
    font-size: 16px;
    color: #222222;
  }

  .thick-span {
    color: #AAAAAA;
    font-size: 16px;
  }

  .extra-border {
    border-bottom: 1px solid #00000008;
  }

  .over-text {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #222222;
  }

  .inform-color {
    color: #FE0843;
    font-size: 14px;
  }

  .brand-text {
    font-size: 16px;

    letter-spacing: 0.16px;
    color: #AAAAAA;
  }

  .brand-name {
    letter-spacing: 0.16px;
    color: #222222;
    font-size: 16px;
  }

  .product {
    font-size: 14px;
  }

  #radioBtn .notActive{
    color: #3276b1;
    background-color: #683365;
  }

  .heading-black_n{
    color: #198754 !important;
  }

  .ecom_detail_list_changes{
    padding-top: 8px;
    
  }

.image_round69{
  text-align: center;
  
}

  @media all and (max-width:768px) {
    .image_round68 {
      font-size: 13px;
    }
}

@media all and (max-width:768px) {
  .image_round70 {
    width: 100%;
  }
}


  

}

.text_settag1{
  background-color: #e72235;
  color: white !important;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 8px;
}

.set-text-view-more{
  font-size: 14px !important;
  color: #e72235 !important;
}

.set-text-view-more1{
  font-size: 14px !important;
}

.set-text-view-more3{
  font-size: 14px !important;
  border-radius: 10px;
}

.set-text-view-more-offer{
  font-size: 13px !important;

}

.set-text-view-more2{
  width: 20px !important;
}

/* styles.css */

.animated-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #fd7e14;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  border-radius: 20px;
}

.animated-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -75%;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  transform: skewX(-25deg);
  animation: shine 2s infinite;
}

@keyframes shine {
  0% {
    left: -75%;
  }
  100% {
    left: 125%;
  }
}




.set-mp{
  margin-top: 10px;
}

.modal {
  display: none; /* Initially hidden */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5); /* Semi-transparent background */
  overflow: auto;
}

.modal.show {
  display: block; /* Displayed when showModal is true */
}



.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.flex-container {
  display: flex;
  align-items: center;
}

.image-class {
  margin-right: 10px;
}

.set-text-view-more1 {
  margin: 0;
}

.set-text-view-more {
  font-weight: bold;
}

.set-mp {
  margin-top: 5px;
}

.bordered-row {
  margin-bottom: 10px;
}

.bordered-col {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.body-image {
  max-width: 70%;
  /* height: auto; */
  /* margin-bottom: 2px; */
  border-radius: 15px;
}
.setvideohw{
  width: 35%;
}

.set-button{
  border-radius: 25px;
}
.set-price{
  font-weight: bold;
}
.set-rating{
  font-size: 17px !important;
}
.bordered-col1 {
  /* border: 1px solid #ddd; */
  /* padding: 10px; */
  text-align: center;
}
.set-review-font{
  font-size: 15px !important;
  color: #000;

}
.set-review-font1{
  font-size: 13px;
  color: #000;
}
.set-report-button{
  color: red;
  font-size: 11px;
}
.typewriter {
  font-family: monospace;
  font-size: 24px;
  margin: 20px;
}

.typewriter p {
  white-space: pre;
}

.set-images{
  width: 100px;
}

.overlay-text{
  position: absolute;
  margin-right: 200px;
  margin-top: 10px;
  color: red;
}

@media (max-width: 768px) {
  .bksassured-image {
    display: block;
    margin-top: 10px; /* Adjust the margin as needed */
  }

  .set-text-view-more1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 769px) {
  .bksassured-image {
    display: inline;
    margin-top: 0;
  }

  .set-text-view-more1 {
    display: inline;
    flex-direction: row;
    align-items: center;
  }
}
.btn-set-off{
  font-weight: bold;
  color: white;
  background-color: #fd7e14;
  font-size: 12px;
}
.set-text-view-more3 {
  width: 33px !important;
}
.set-bg-color{
  background-color: beige;
}
.getquote-ntn2{
  width: 100%;
  height: 28px;
  background: var(--unnamed-color-818181) 0% 0% no-repeat padding-box;
  background: var(--bs-orange) 0% 0% no-repeat padding-box;
  color: var(--bs-white);
  border: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
}

.set-text-offers{
  text-align: end;
  margin-top: 12px;
}

.setvideohw1{
  width: 100%;
  height: 262px;
}
.set-reviewvideo{
  margin-top: 188px;
  margin-left: 25px;
  position: absolute;
}
.set-reviewvideo1{
  position: absolute;
  margin-top: 352px;
  margin-left: 10px;
  color: red;
  font-weight: bold;
  font-size: 14px;
  

}    

.set-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-image {
  height: 250px;
  object-fit: cover;
}
.set-reviewphoto{
  position: absolute;
  margin-top: 35px;
}  
.set-border{
  border: solid 1px gray;
  padding: 10px;
}  
.set-text-offers1 {
  text-align: end;

}
.star-rating{
  color: #fd7e14 !important;
}
.set-login-logo{
  width: 280px;
}
.set-colorbg{
  background-color: green;
}
.set-model-login{
  font-weight: bold;
  font-size: 18px;
  color: green;
}    
.set-model-login1{
  font-size: 16px;
  margin-top: 50px;
  // text-align: center;
  padding-left: 60px;
 
}
.animated-button1 {
  border: #07bc0c;
  border-radius: 4px;
  background-color:#37bf3a;
  color: #fff;
  font-size: 15px;
  height: 32px;
  border-radius: 25px;
  font-weight: bold;
  width: 75%;
}

.set-login-logo1 {
    width: 100%;
    background-color: green;
}
.email-input {
  margin-top: 7px;
  width: 75%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E7E7E7;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
}
.set-text-login{
  text-align: center;
}

.setModelColorBg{
  background-color: white
}
.set-model-login2{
  font-weight: bold;
  font-size: 16px;
  color: green;

}

.set-model-login3{
  font-weight: bold;
  font-size: 15px;
  color: white;
  background-color: green;
  height: 63px;
}

.set-button-set{
  font-size: 12px;
  border-radius: 13px;
  margin-left: 79px;
}
.set-text1{
  text-align: center;
}
.set-width{
  width: 100px;
}

/* Styles for specific modals */
.custom-modal .modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
}
.setmp{
  margin-left: 42px;
}




/* src/Slider.css */

.slider_container {
  display: flex;
}

.thumbnail-slider {
  width: 20%;
}

.main-image-slider {
  width: 80%;
}

.slick-dots.vertical-dots {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.thumbnail-image-slide {
  margin-bottom: 10px;
}

.thumbnail-image {
  width: 100%;
  height: auto;
}

.big-image-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.overlay-text1 {
  position: absolute;
  bottom: 329px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.set-images {
  height: 20px;
  width: auto;
}
.slider_container{
  height: 421px;
}

.set-imageslider{
  height: 375px;
  width: 370px
}

@media (max-width: 768px) {
  .set-imageslider{
    height: 375px;
    width: 260px
   }
  }

@media (min-width: 769px) {
  .set-imageslider{
    height: 375px;
    width: 370px
  }
}

.ml5{
  margin-left: 41px;
}
.ml6{
  margin-left: 10px;
}

.set-offerbanner{
  background-color: aliceblue;
  height: 120px;
  padding-top: 35px;
  border: dotted 3px green;
}
.semp{
  margin-right: 15px;
}

.ecom_detail_list_changes {
  cursor: pointer;
  text-align: center;
  padding: 10px; /* Add padding to create space for the border */
}

.ecom_detail_list_changes.selected {
  border: 2px solid #007bff; /* or any color you want to indicate selection */
  border-radius: 5px;
}

.ecom_detail_list_changes span {
  display: block;
  margin: auto;
}



   